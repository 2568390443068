.home-content {
  display: flex;
  flex-direction: column;
  margin-top: -12px;

  &-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  .home-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin-bottom: 24px;
    padding: 0 $space_m;

    .prom-title, .prom-subtitle, .prom-text {
      text-align: center;
    }

    .prom-subtitle {
      margin: $space_s 0;
    }
  }

  .home-products-container {
    //width: 100%;
    display: block;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .prom-title-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .prom-title, .prom-subtitle {
      font-size: 18px;
      white-space: nowrap;
    }
    .prom-title {
      margin-right: 12px;
    }
  }

  .home-products-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //width: 400px;
    //height: 640px;
    border-radius: 10px;
    margin: 25px $space_m;
    background: $primary;

    &--title {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px 10px 0 0;
      background: $secondary;

      .prom-title {
        color: $white;
        text-align: center;
      }
    }

    &--text {
      padding: 5px 15px;

      &--main-page-block2 {
        text-align: center;
      }

      .prom-text {
        color: $white;
        text-align: center;

        .row {
          display: flex;
        }

        .column {
          flex: 50%;
        }
      }
    }

    &--more {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: $space_m;

      a {
        color: $white;
      }

      .video-button {
        position: absolute;
        margin-top: -10px;
        margin-left: 20px;
      }

      .sugar-button {
        margin-right: 110px;
      }

      .more-button {
        padding: $space_xs $space_l;
        border: 2px solid $white;
        margin-bottom: $space_m;

        &:hover {
          background: $secondary;
        }
      }
    }

    .microplastic-image {
      display: inline-block;
      //border: 1px solid red;
      padding-left: 28%;
      margin:10px;
      inline-size: fit-content;

      img:last-child {
        margin: 5px;
        width: 37%;
      }
    }

    .automation-image {
      display: inline-block;
      padding-left: 27%;
      margin:10px;
      inline-size: fit-content;

      img:last-child {
        margin: 6px;
        width: 29%;
      }
    }

    .varka-image {
      display: inline-block;
      padding-left: 23%;
      margin:10px;
      inline-size: fit-content;

      img:last-child {
        margin: 6px;
        width: 29%;
      }
    }

    .automation-description {
      display: inline-block;
      padding-left: 12%;
      padding-right: 10%;
      margin:10px;
      inline-size: fit-content;
    }
  }
}