.sugar-content {
  display: flex;
  flex-direction: column;
  padding: $space_m;

  .sugar-cooking-page-title {
    font-size: xxx-large;
    width: 75%;
    text-align: center;
    color: darkred;
    padding-bottom: 30px;
  }

  .sugar-cooking-page-paragraph {
    font-size: xx-large;
    width: 75%;
    text-align: left;
    color: #1a1a1a;
  }

  .sugar-cooking-page-sub-title {
    font-size: xx-large;
    width: 60%;
    text-align: left;
    color: #337ab7;
  }

  .sugar-cooking-page-princip-robotu-title {
    font-size: xxx-large;
    text-align: center;
    color: #337ab7;
  }

  .sugar-cooking-page-image-desc {
    font-size: xxx-large;
    text-align: center;
    color: #337ab7;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .sugar-cooking-image {
    text-align: center;
  }

  .img-varka-slides {
    width: 75%;
  }

  .img-varka-7 {
    padding-top: 50px;
  }

  .img-varka-8 {
    padding-top: 50px;
  }

  .img-varka-9 {
    padding-top: 50px;
  }

  &--row {
    padding: $space_s 0;
  }

  &--subtitle {
    @extend .prom-subtitle;
    text-align: center;
    margin-top: $space_l;
  }

  &-paragraph {
    display: flex;
    margin: $space_s 0;
    @extend .prom-text;
    text-align: justify;
  }

  &--list {
    display: flex;
    flex-direction: column;

    @extend .prom-text;
    padding: 0 $space_xxl;
  }

  &--list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $space_s 0;

    &--icon {
      svg {
        fill: $secondary;
      }
    }

    &--text {
      margin-left: $space_s;
    }
  }

  .video-block {
    //margin-left: 20%;
    //margin-right: 20%;
  }

  .video-iframe {
    text-align: center;
    margin-top: 25px;
  }

  .video-2 {
    margin-top: 50px;
  }

  .with-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .sugar-cooking-image-alt {
    @extend .prom-text;
    font-family: "Helvetica Neue", serif;
    font-weight: bold;
  }
}