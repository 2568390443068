@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import "./core/colors";
@import "./core/spaces";
@import "./core/responsive";
//@import "./core/fonts";
@import "./core/typography";

@import "./components/layout";

@import "./pages/home";
@import "./pages/about";
@import "./pages/contacts";
@import "./pages/massecuite";
@import "./pages/equipment";
@import "./pages/sugar-cooking";
@import "./pages/cvetomer";
@import "./pages/microplastic";

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", serif;
  color: $gray;
  font-size: $size-regular;
  background-size: cover;
  background: url("../images/bg.png");
}

a {
  text-decoration: none;
  color: $secondary;
}