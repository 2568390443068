$size-regular: 14px;
$size-label: 16px;
$size-title: 22px;
$size-subtitle: 18px;

.prom-title {
  font-family: "Helvetica Neue", serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: $size-title;
  color: $secondary;
}

.prom-subtitle {
  font-family: "Helvetica Neue", serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: $size-subtitle;
}

.prom-text {
  font-family: "Helvetica Neue", serif;
  font-size: $size-regular;
  letter-spacing: 1px;
}