.contacts-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: $space_m;

  &-block {
    width: 400px;
    margin: 0 $space_m;
    .contacts__address {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      padding: $space_m;

      &--row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: $space_m;

        &_icon {
          width: 24px;
          height: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          svg {
            fill: $primary;
          }
        }

        &_text {
          margin-left: $space_s;
        }
      }
    }
  }
}