.prom__layout--header {
  display: flex;
  flex-direction: column;

  &_top-bar {
    display: flex;
    flex-direction: row;
    height: 100px;
    margin: 0 $space_m;
    font-family: "Helvetica Neue", serif;
    font-weight: bold;

    svg {
      width: 24px;
      height: 24px;
      fill: $primary;
    }

    .top-bar__logo {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      img {
        width: 100%;
      }
    }

    .top-bar__address {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      padding-left: $space_m;

      &--row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &_icon {
          width: 24px;
          height: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

        }

        &_text {
          margin-left: $space_s;
        }
      }
    }

    .top-bar__email {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: $space_m;

      &--row {
        display: flex;
        flex-direction: row;
        align-items: center;

        &_icon {
          width: 24px;
          height: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

        }

        &_text {
          margin-left: $space_s;
        }
      }
    }

    .top-bar__lang {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: $space_m;

      &--row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;

        &_icon {
          width: 24px;
          height: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

        }

        &_text {
          margin-left: $space_s;
        }
      }
    }
  }

  &_navigation {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: $primary;
    margin: 0;
    padding: 0;

    .prom-navigation {
      display: inline-flex;
      list-style: none;
    }

    .prom-navigation-link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: $size-subtitle;
      font-family: "Helvetica Neue", serif;
      font-weight: bold;
      width: 100%;
      white-space: nowrap;
      border-bottom: 3px solid transparent;
      margin: 0 $space_s;
      padding: $space_xxs $space_s;
      transition: border-bottom 0.5s ease-out;

      &:hover {
        border-bottom: 3px solid $white;
      }

      a {
        color: $white;
      }
    }

    .active {
      border-bottom: 3px solid $white;

      a {
        color: $white;
      }
    }
  }
}
