.equipment-content-wrapper {
  margin-top: $space_m;
}

.moisture-content {
   display: flex;
   flex-direction: column;
   padding: $space_m;

   &--row {
     padding: $space_s 0;
   }

   &--subtitle {
     @extend .prom-subtitle;
     text-align: center;
     margin-top: $space_l;
   }

   &-paragraph {
     display: flex;
     margin: $space_s 0;
     @extend .prom-text;
     text-align: justify;
   }

   &--list {
     display: flex;
     flex-direction: column;

     @extend .prom-text;
     padding: 0 $space_xxl;
   }

   &--list-item {
     display: flex;
     flex-direction: row;
     align-items: center;
     padding: $space_s 0;

     &--icon {
       svg {
         fill: $secondary;
       }
     }

     &--image {
       margin-left: $space_s;
       object-fit: cover;

       img {
         width: 300px;
         height: 200px;
       }
     }

     &--text {
       margin-left: $space_s;
     }
   }

   .with-image {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
   }

   .moisture-image-alt {
     @extend .prom-text;
     font-family: "Helvetica Neue", serif;
     font-weight: bold;
   }
 }