.microplastic-page-title {
  font-size: xx-large;
  margin-right: 100px;
  margin-left: 100px;
  margin-bottom: 50px;
  text-align: center;
}

.microplastic-page-text {
  font-size: x-large;
  margin-right: 100px;
  margin-left: 100px;
  margin-bottom: 10px;
  text-align: left;
}

.microplastic-page-list {
  font-size: x-large;
}

.microplastic-page-link {
  font-size: x-large;
  padding-bottom: 20px;

  a {
    text-decoration: underline;
    color: $linkRed;
  }

}

.microplastic-content {
  display: flex;
  flex-direction: column;

  &--row {
    padding: $space_s 0;
  }

  &--subtitle {
    @extend .prom-subtitle;
    text-align: center;
    margin-top: $space_l;
  }

  &-paragraph {
    display: flex;
    margin: $space_s 0;
    @extend .prom-text;
    text-align: justify;
  }

  &--list {
    display: flex;
    flex-direction: column;

    @extend .prom-text;
    padding: 0 $space_xxl;
  }

  &--list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $space_s 0;

    &--icon {
      svg {
        fill: $secondary;
      }
    }

    &--text {
      margin-left: $space_s;
    }
  }

  .with-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .microplastic-image-alt {
    @extend .prom-text;
    font-family: "Helvetica Neue", serif;
    font-weight: bold;
  }

  .microplastic-image {
    margin-bottom: 35px;
  }
}