.massecuite-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: $space_m 0;

  .MuiTabs-flexContainer {
    min-width: 300px;
  }

  .Mui-selected {
    background: $primary;
    color: $white;
  }

  div[role="tabpanel"] {
    width: 100%;
  }
}

.massecuite-tab-content-wrapper {
  width: calc(100% - 24px);
  margin: 0 $space_m;
}

.massecuite-tab-content {
  text-align: center;
  font-size: xx-large;
  margin-bottom: 25px;

  &--title {
    @extend .prom-title;
    text-align: center;
  }

  &--subtitle {
    @extend .prom-subtitle;
    text-align: center;
    margin-top: $space_l;
  }

  &--paragraph {
    @extend .prom-text;
    padding: $space_s 0;
    text-align: justify;
  }

  &--list {
    display: flex;
    flex-direction: column;

    @extend .prom-text;
    padding: 0 $space_xxl;
  }

  &--list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $space_s 0;

    &--icon {
      svg {
        fill: $secondary;
      }
    }

    &--text {
      margin-left: $space_s;
    }
  }
}

.MuiTableCell-head {
  @extend .prom-subtitle;
  color: $primary!important;
}

.results-container {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    text-align: left;
    margin-top: $space_xs;

    &:first-child {
      margin-top: 0;
    }
  }
}

.massecuite-schema-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.massecuite-gallery-wrapper {
  padding: $space_m 0;

  .image-gallery-thumbnails {
    display: none;
  }
}

.automation-page-headline {
  text-align: center;
  font-size: xx-large;
  margin-bottom: 25px;
}

.automation-page-text {
  font-size: medium;
  margin-bottom: 25px;
  margin-left: 100px;
  margin-right: 100px;
  text-align: left;
}

.automation-page-image {
  text-align: center;
  margin-bottom: 25px;
}