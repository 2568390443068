@import "./header";
@import "./footer";

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prom__layout--content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: hidden;
  margin: $space_m;

  &-title {
    height: 40px;
    border-bottom: 3px solid $secondary;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @extend .prom-title;
  }
}